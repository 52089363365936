import { TextContent } from "../coming-soon-us/_types"

export const TEXT_CONTENT: TextContent = {
  heading: 'Em Breve',
  title: 'O Inter&Co está com você no Brasil!',
  description: 'Seu super app será o parceiro perfeito para suas viagens. Estamos trazendo novas funcionalidades para você aproveitar ao máximo sua estadia no Brasil.',
  menuOptions: [
    {
      icon: 'brands/ic_pix',
      title: 'Pix',
      description: 'Transferências locais rápidas e descomplicadas. Envie e receba dinheiro no Brasil de forma simples e eficiente.',
    },
    {
      icon: 'brands/ic_inter_loop',
      title: 'Loop',
      description: 'Continue acumulando pontos Loop enquanto aproveita sua viagem.',
    },
    {
      icon: 'shopping/ic_gift',
      title: 'Marketplace',
      description: 'Explore novas marcas e faça suas compras no marketplace brasileiro, tudo diretamente pelo super app!',
    },
    {
      icon: 'finance/ic_cashback',
      title: 'Cashback',
      description: 'Compre gift cards de marcas brasileiras e receba cashback. Uma ótima maneira de economizar durante suas viagens!',
    },
  ],
}

export const BANNER_IMAGE_URL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webview_banner_coming_soon_pt/image.webp'
export const FLUXO_TAG = 'COMINGSOON_ACESSO_FLUXO'
export const ACESSO_TAG = 'COMINGSOON_ACESSO'
export const PRODUCT_NAME = 'coming-soon'
